#home {
    width: 100%;
    max-height: 140vh;
    padding-top: 80px;
    position: relative;
    z-index: -1;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    animation: fadeIn 1s ease-in-out;
    position: relative;
    overflow: hidden;

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    &.home-section {
        max-height: 140vh;
        @media screen and (max-width: 768px) {
            height: auto;
        }
        background-repeat: no-repeat;
        background-size: cover;
    }

    .title h1 {
        background-color: white;
        border-right: .15em solid orange;
        white-space: nowrap;
        letter-spacing: .15em;
        animation:
            typing 1s steps(40, end),
            blink-caret 0.75s step-end infinite;
    }

    @keyframes typing {
        from {
            width: 0;
        }

        to {
            width: 100%;
        }
    }

    @keyframes blink-caret {

        from,
        to {
            border-color: transparent;
        }

        50% {
            border-color: transparent;
        }
    }

    .tagline {
        background-color: white;

        h3 {
            font-size: 32px;
            color: black;
            opacity: 0;
            animation: fadeIn 1s ease-in-out forwards;
        }

        /* Fade-in Animation */
        @keyframes fadeIn {
            from {
                opacity: 0;
                transform: rotate(0deg);
            }

            to {
                opacity: 1;
                transform: rotate(360deg);
            }
        }

    }

}