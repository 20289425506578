
.project-item{
    max-height: 300px !important;
    max-width: 335px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover{
     cursor: pointer;
    }
}