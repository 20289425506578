.offcanvas-start .show {
    padding: 20px !important;

    .offcanvas-body {
        li>a {
            text-decoration: none !important;
        }
    }
}

.sidebar-item{
    &:hover{
        cursor: pointer;
    }
}

#sidebar-accordion {
    .accordion-item {
        border: none !important;
    }

    .accordion-button {
        padding: 0;
    }

    .accordion-collapse {
        padding: 0 !important;
    }
    .accordion-button:not(.collapsed){
        background-color: transparent !important;
        box-shadow: none !important;
    }
    .accordion-button:focus{
        box-shadow: none !important;
    }
    .sidebar-project-items {
        margin: 10px 0 !important;
        &:hover{
            cursor: pointer;
        }
    }
}