#our-projects{
    background-color: cornsilk;
    padding: 100px;
    min-height: 90vh;
.left{
    border-right:  1px solid black;
}
    @media screen and (max-width: 768px) {
        padding: 20px;

        .left{
            border-right: none;
        }
    }
}