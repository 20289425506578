#section-why-us {
    background-color: #00FFFF;
    min-height: 130vh;
    padding-top: 90px;
    width: 100%;
    position: relative;
    z-index: -1;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    background-image: url("../../assets/images/why_us_bg.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .card {
        max-height: 225px !important;
        font-size: 14px;
        h5 {
            font-weight: 600 !important;
        }

        @media screen and (max-width: 930px) {
            min-height: 250px !important;
        }

        @media screen and (max-width: 768px) {
            min-height: 140px !important;
        }

        &:hover {
            box-shadow: rgba($color: red, $alpha: 1.0);
        }
    }
}