#header{
    background-color: white;
    height: 80px;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: 768px) {
        justify-content: start;
    }
    .sidebar {
        &:hover {
            cursor: pointer;
        }
    }
    
    #header-logo {
        animation: fadeIn 1s ease-in-out;
    
        @keyframes fadeIn {
            0% {
                opacity: 0;
            }
    
            100% {
                opacity: 1;
            }
        }
    
        &:hover {
            cursor: pointer;
        }
    
        .sidebar-menu {
            height: 10px;
            width: 10px;
        }
    }
    .tagline-text{
        background-color: rgba($color: #FFF, $alpha: 0.2);
    }
}